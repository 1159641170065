import PlatformsView from 'Components/PlatformsView';
import StandardFormsView from 'Components/StandardForms/StandardFormsView';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingScreen from '../Components/LoadingScreen';
import Locations from '../Components/Locations';
import KombitView from 'Components/Kombit/KombitView';
import StandardTasksView from 'Components/StandardTasks/StandardTasksView';
import CustomerCountView from 'Components/CustomerCount/CustomerCountView';
import TogglesPage from 'Components/Toggles/TogglesPage';

export const Router: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/locations" />
        </Route>
        <Route path="/locations">
          <Locations />
        </Route>
        <Route path="/toggles">
          <TogglesPage />
        </Route>
        <Route path="/platforms">
          <PlatformsView />
        </Route>
        <Route path="/standardforms">
          <StandardFormsView />
        </Route>
        <Route path="/kombit">
          <KombitView />
        </Route>
        <Route path="/standardtasks">
          <StandardTasksView />
        </Route>
        <Route path="/customercount">
          <CustomerCountView />
        </Route>
      </Switch>
    </Suspense>
  );
};
