import { Box, Typography, Grid, Button } from '@mui/material';
import React, { useState } from 'react';
import CreateLocationDialog from './CreateLocationDialog';
import { useFetchCustomer } from 'requests/hooks/useFetchCustomer';
import useCreateOrganisation from 'requests/hooks/useCreateOrganisation';
import { Apartment, CreateLocationRequest, Department, Organisation } from 'requests/nswag/atlas/AtlasClient';
import OrganisationDotMenu from 'Components/OrganisationDotMenu';
import DepartmentDotMenu from 'Components/DepartmentDotMenu';
import ApartmentDotMenu from 'Components/ApartmentDotMenu';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';

export default function Locations() {
  const customer = useCustomerContext();
  const { data } = useFetchCustomer(customer.id);
  const [openCreateOrganisation, setOpenCreateOrganisation] = useState(false);
  const createOrganisationMutation = useCreateOrganisation();
  const [parentId, setParentId] = useState<string>('');

  function createOrganisation(name: string | undefined) {
    if (name !== undefined) {
      createOrganisationMutation.mutate(new CreateLocationRequest({ parentId, name }));
      setOpenCreateOrganisation(false);
    }
  }

  if (data == null) return null;

  function renderOrganisations(organisations: Organisation[]) {
    return organisations.map((org) => {
      return (
        <TreeItem
          key={org.id}
          itemId={org.id}
          label={
            <Grid container direction="row" justifyContent="space-between">
              <Typography p={1}>{org.name}</Typography>
              <OrganisationDotMenu organisationId={org.id} organisationName={org.name} />
            </Grid>
          }
        >
          {renderDepartments(org.departments)}
        </TreeItem>
      );
    });
  }

  function renderDepartments(departments: Department[]) {
    return departments.map((dep) => {
      return (
        <TreeItem
          key={dep.id}
          itemId={dep.id}
          label={
            <Grid container direction="row" justifyContent="space-between">
              <Typography p={1}>{dep.name}</Typography>
              <DepartmentDotMenu departmentId={dep.id} departmentName={dep.name} />
            </Grid>
          }
        >
          {renderApartments(dep.apartments)}
        </TreeItem>
      );
    });
  }

  function renderApartments(apartments: Apartment[]) {
    return apartments.map((apt) => {
      return (
        <TreeItem
          key={apt.id}
          itemId={apt.id}
          label={
            <Grid container direction="row" justifyContent="space-between">
              <Typography p={1}>{apt.name}</Typography>
              <ApartmentDotMenu apartmentId={apt.id} apartmentName={apt.name} />
            </Grid>
          }
        />
      );
    });
  }

  return (
    <Box m={6}>
      <Grid container direction="row" justifyContent="space-between">
        <Typography style={{ fontSize: 32 }}>{customer.name}</Typography>
        <Button
          onClick={() => {
            setParentId(customer.id);
            setOpenCreateOrganisation(true);
          }}
        >
          Add organisation
        </Button>
      </Grid>
      <Box mb={2}>
        <SimpleTreeView sx={{ flexGrid: 1 }} disableSelection>
          {renderOrganisations(data.organisations)}
        </SimpleTreeView>
      </Box>
      <CreateLocationDialog
        title={'Create Orginasation'}
        open={openCreateOrganisation}
        setOpen={setOpenCreateOrganisation}
        createLocation={createOrganisation}
        isLoading={createOrganisationMutation.isPending}
      />
    </Box>
  );
}
